<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="pa-40 flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>출금 계좌 등록</h4>
					<v-icon
						@click="cancel"
					>mdi-close-circle</v-icon>
				</div>

				<div
					class=" overflow-y-auto pa-10 "
					style="max-height: 480px"
				>
					<template
						v-if="is_holder"
					>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank_code"
									class=""
									disabled=""
									style="border: unset; color: black;"
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2">{{ item_withdrawal.bank_account }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2" :class="{ 'color-red': !item_holder.realBankOwnerName }">{{ item_holder.realBankOwnerName ? item_holder.realBankOwnerName : '출금계좌를 다시 확인하세요' }}</div>
						</div>
					</template>

					<template
						v-else
					>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank_code"
									class="input-box "
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_account" class="input-box " placeholder="출금 계좌를 입력하세요" maxlength="50" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_holder" class="input-box " placeholder="예금주를 입력하세요" maxlength="50" /></div>
						</div>
						<div
							v-if="is_holder"
							class="mt-10 flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold color-red">예금주 확인</div>
							<div class="flex-2 "><input class="input-box text-right" placeholder="입금자명을 입력하세요" maxlength="10" disabled :value="item_holder.realBankOwnerName" /></div>
						</div>
					</template>
				</div>

				<div
					class="mt-auto "
				>
					<button
						v-if="is_holder"
						@click="onPin"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_confirm"
					><slot name="name-confirm">저장</slot></button>
					<button
						v-else
						@click="postHoder"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_withdrawal"
					><slot name="name-confirm">예금주 조회</slot></button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'UserAccount'
	, components: {PopupLayer}
	, props: ['user', 'shop_info']
	, data: function(){
		return {

			item_withdrawal: {
				bank_code: ''
				, bank_account: ''
				, bank_holder: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, shop_uid: this.shop_info.shop_uid
			}
			, is_holder: false
			, item_holder: {}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function () {
			let t = true
			if (this.item_withdrawal.bank_code && this.item_withdrawal.bank_account && this.item_withdrawal.bank_holder) {
				t = false
			}

			return t
		}
		, is_to_merchant_confirm: function () {
			let t = true
			if (this.item_withdrawal.bank_code && this.item_withdrawal.bank_account && this.item_withdrawal.bank_holder && this.item_holder.realBankOwnerName) {
				t = false
			}

			return t
		}
	}
	, methods: {

		postAccount: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postAccount'
					, data: this.item_withdrawal
				})

				if (result.success) {
					this.$emit('click')
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postHoder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: this.item_withdrawal
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
					this.item_withdrawal.bank_holder = this.item_holder.realBankOwnerName
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
				console.log('pinCallback', call)
				this.postAccount()
			})
		}
		, cancel: function(){
			if(this.is_holder){
				this.is_holder = false
			}else{
				this.$emit('cancel')
			}
		}
	}
	, created() {
	}
}
</script>